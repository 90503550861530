import React from 'react'

// Styles
import cn from './index.module.sass'

// Components
import Container from '../../layout/Container'
import Wrapper from '../../layout/Wrapper'

const Banner = ({children, cName, style}) => (
  <Container cName={`${cn.container} ${cName}`} style={style}>
    <Wrapper>
      {children}
    </Wrapper>
  </Container>
)

export default Banner
