import React from 'react'

// Images
// import imgYoutube from "../../../../images/logos/third-party/andrew-harper-music-logo-youtube-text-orlando-florida.png"
import imgSpotify from "../../../../images/logos/third-party/andrew-harper-music-logo-spotify-text-orlando-florida.png"

// Components
import cn from './index.module.sass'
import Banner from '../../../custom/Banner'

const BannerSpotify = () => (
  <>
    <Banner cName={cn.background}>
      <section className={cn.container}>
        <div className={cn.container__content}>
          <h4 className={cn.title}>Now on SPOTIFY!</h4>
          <p className={cn.text}>I am so excited to be sharing my music on Spotify for free! May the songs be a blessing to your day. Enjoy!</p>
        </div>
        <div className={cn.container__link}>
          <a className={cn.link} name='link to spotify' href='https://open.spotify.com/album/5rA4guMvc42uvCBZtvnuxy?si=13-gMcESRKK_surLnzMsbQ'><img className={cn.image} src={imgSpotify} alt='spotify link to listen to my music for free'/></a>
          {/* <a className={cn.link}><img className={cn.image} src={imgYoutube}/></a> */}
        </div>
      </section>
    </Banner>
  </>
)

export default BannerSpotify