import React from 'react'
import Helmet from 'react-helmet'

// Components
import Layout from '../components/layout'
import BannerSpotify from '../components/pages/home/BannerSpotify'
import About from '../components/pages/home/About'
import Music from '../components/pages/home/Music'
import BannerPatreon from '../components/pages/home/BannerPatreon'

const IndexPage = () => (
  <>
    <Helmet
      title='Andrew Harper Music | Christian Worship Artist'
      meta={[
        { name: 'description', content: 'Singer, Songwriter, and Servant of God. Inspired by his relationship with Christ through lifes struggles, Andrew Harper writes songs of encouragement for those struggling through the toughest days.'},
        { name: 'keywords', content: 'andrew harper music, andrew harper, christian worship artist, christian musician, christian song writer, christian music artist, ahm'},
        { name: 'author', content: 'Andrew Harper'},
      ]}
    />
    <Layout>
      <BannerSpotify/>
      <About/>
      <BannerPatreon/>
      <Music/>
    </Layout>
  </>
)

export default IndexPage
