import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons'

// Components
import cn from './index.module.sass'

export default class AudioItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      duration: 0,
      currentTime: 0,
      button: <FontAwesomeIcon icon={faPlay}/>,
    }

    // Extract incoming audio data from props
    this.title = this.props.song.title    
    if(typeof document !== 'undefined') {
      this.song = document.createElement('audio')
    }
    else {
      this.song = {}
    }
    this.song.src = this.props.song.src

    this.toggleSongPlay = this.toggleSongPlay.bind(this)
    this.setSongProgress = this.setSongProgress.bind(this)
    this.getSongDuration = this.getSongDuration.bind(this)
    this.playSong = this.playSong.bind(this)
    this.pauseSong = this.pauseSong.bind(this)
  }

  formatTime(time) {
    let minutes = Math.floor(time / 60)
    let seconds = Math.ceil(time % 60)

    if(seconds < 10) {
      seconds = '0'+seconds
    }

    return minutes+':'+seconds
  }

  toggleSongPlay() {
    if(this.song.paused) {
      this.song.play()
      this.setState({button: <FontAwesomeIcon icon={faPause}/>})
    }
    else {
      this.song.pause()
      this.setState({button: <FontAwesomeIcon icon={faPlay}/>})
    }
  }

  playSong() {
    this.song.play()
    this.setState({button: <FontAwesomeIcon icon={faPause}/>})
  }

  pauseSong() {
    this.song.pause()
    this.setState({button: <FontAwesomeIcon icon={faPlay}/>})
  }

  getSongDuration() {
    this.song.addEventListener('loadedmetadata', e => {
      this.setState({duration: this.song.duration})
    })
  }
  
  setSongProgress(e) {
    if(e.target.value >= this.state.duration || e.target.value <= 0) {
      this.pauseSong()
    }
    this.song.currentTime = e.target.value
    this.setState({currentTime: e.target.value})
  }

  getSongProgress() {
    this.song.addEventListener('timeupdate', () => {
      this.setState({currentTime: this.song.currentTime})
      if(this.state.currentTime >= this.state.duration) {
        this.pauseSong()
      }
    })
  }

  componentDidMount() {
    this.getSongDuration()
    this.getSongProgress()
  }

  render() {
    return(
      <section className={cn.container}>
        <div className={cn.container__controls}>
          <button onClick={this.toggleSongPlay} className={cn.btn__play}>{this.state.button}</button>
          <h5 className={cn.title}>{this.props.song.title}</h5>
        </div>
        <span className={cn.duration}>{`${this.formatTime(this.state.currentTime)} / ${this.formatTime(this.state.duration)}`}</span>
        <div className={cn.progressBar__outer}>
          <div className={cn.progressBar__inner} style={{width: `${(this.state.currentTime / this.state.duration)*100}%`}}/>
          <input type='range' min={0} step={`${(this.state.duration / 1000)}`} max={`${this.state.duration}`} value={`${(this.state.currentTime)}`} onInput={this.setSongProgress} onChange={()=>{}} className={cn.slider}/>
        </div>
      </section>
    )
  }
}