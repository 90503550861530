import React from 'react'

// Images
import imgHandsTogether from '../../../../images/graphics/andrew-harper-music-banner-hands-together-orlando-florida.png'

// Components
import cn from './index.module.sass'
import Banner from '../../../custom/Banner'

const BannerPatreon = () => (
  <>
    <Banner cName={cn.background}>
      <section className={cn.container}>
        <div className={cn.container__title}>
          <h4 className={cn.title}>Patreon</h4>
          <img className={cn.image} src={imgHandsTogether} alt='hands together icon for patreon'/>
        </div>
        <div className={cn.container__text}>
          <p className={cn.text}>Prayer above all else is always a need. It is by God and His strength that I have this gift to share! I cannot express my gratitude enough to those who decide to support me. If you would consider financial support check out my patreon! God bless.</p>
        </div>
        <div className={cn.container__link}>
          <a className={cn.link} name='link to patreon' href='https://www.patreon.com/AndrewHarperMusic'>Visit Patreon</a>
        </div>
      </section>
    </Banner>
  </>
)

export default BannerPatreon