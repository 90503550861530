import React from 'react'

// Images
import imgBackAbout from "../../../../images/pictures/andrew-harper-music-about-worship-stage-orlando-florida.jpg"
import imgAbout from '../../../../images/pictures/about/andrew-harper-music-about-info-orlando-florida.jpg'

// Components
import cn from './index.module.sass'
import Container from '../../../layout/Container'
import Wrapper from '../../../layout/Wrapper'
import SectionTitle from '../../../custom/SectionTitle'

const backAbout = {
  background: `url(${imgBackAbout}) no-repeat center/cover`
}

const About = () => (
  <Container id='about' style={backAbout}>
    <Wrapper>
      <SectionTitle id='about' title='About' subtitle='Andrew Harper' right whiteText whiteBorder/>
      <section className={cn.container}>
        <img className={cn.image} src={imgAbout} alt='worshiping on the piano'/>
        <p className={cn.text}>I was born into a God-fearing home as the son of a pastor. I know the pastor's kid title has a negative connotation to it and for a while I was on my way to fulfill that expectation. That is, until God grabbed my attention on a missions trip to Peru and challenged me to be a man after my God. Through the following months He taught me to use the gifts He gave me to glorify Him instead of myself and I began to lead worship, share written songs, and record these songs for His purposes. This is my story so far and hope you will join me in seeing how the Lord continues it!</p>
      </section>
      <SectionTitle subtitle='Ways to Support' bottom small whiteText whiteBorder/>
    </Wrapper>
  </Container>
)

export default About
