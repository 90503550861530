import React from 'react'

// Components
import cn from './index.module.sass'
import Container from '../../../layout/Container'
import Wrapper from '../../../layout/Wrapper'
import SectionTitle from '../../../custom/SectionTitle'
import AudioItem from '../../../custom/AudioItem'

const galleryData = [
  {
    src: require('../../../../images/pictures/music/andrew-harper-music-playing-for-hannah-orlando-florida.jpg'),
    alt: 'Playing guitar for my niece Hannah'
  },
  {
    src: require('../../../../images/pictures/music/andrew-harper-music-surf-camp-worship-orlando-florida.jpg'),
    alt: 'Leading worship at Urban Surf camp'
  },
  {
    src: require('../../../../images/pictures/music/andrew-harper-music-leading-concert-calvary-chapel-orlando-florida.jpg'),
    alt: 'Playing guitar for my niece Hannah'
  },
  {
    src: require('../../../../images/pictures/music/andrew-harper-music-castledoor-recording-studio-session-orlando-florida.jpg'),
    alt: 'Playing guitar for my niece Hannah'
  },
  {
    src: require('../../../../images/pictures/music/andrew-harper-music-concert-at-calvary-chapel-orlando-florida.jpg'),
    alt: 'Playing guitar for my niece Hannah'
  },
  {
    src: require('../../../../images/pictures/music/andrew-harper-music-castledoor-recording-booth-orlando-florida.jpg'),
    alt: 'Playing guitar for my niece Hannah'
  },
]

const songData = [
  {
    title: 'Ships at Sea',
    src: require('../../../../audio/ships_at_sea_demo.mp3')
  },
  {
    title: 'Our God and King',
    src: require('../../../../audio/our_god_and_king_demo.mp3')
  },
  {
    title: 'We Wait for You',
    src: require('../../../../audio/we_wait_for_you_demo.mp3')
  },
]

const Music = () => (
  <Container id='music'>
    <Wrapper>
      <SectionTitle title='Music' subtitle='A Joyful Sound' left/>
      <div className={cn.image__header}/>
      <section className={cn.container}>
        <section className={cn.container__content}>
          <p>Every song has a story behind it. Much of my music is inspired by the many lessons the Lord has taught me, prayers in quiet time, or new realizations about the character of God. All have the same purpose of placing God at the center of every situation. I have never been able to identify what genre of music I belong to and I am really okay with that. What makes this all so special is that the Lord is the one who gives me all these ideas and it shows in how different each song is from the last. I will continue to offer up my best effort and lean on Him who gives abundantly. May these songs be an encouragement to those who could use it, a light for those who are searching, or a song of praise for those who have reason to sing!</p>
          <h3><b>Song Demos</b></h3>
          {songData.map((song, i) => (
            <AudioItem key={i} song={song}/>
          ))}
        </section>
        <section className={cn.container__gallery}>
          <ul className={cn.gallery__list}>
            {galleryData.map((item, i) => (
              <li key={i} className={cn.gallery__item}><img className={cn.gallery__image} src={item.src} alt={item.alt}/></li>
            ))}
          </ul>
        </section>
      </section>
    </Wrapper>
  </Container>
)

export default Music
